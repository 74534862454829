import { Expose } from 'class-transformer';
import { ShortLinkType } from './IUserContent';

export type Platform = 'mobile' | 'desktop' | 'portrait';

export class PageInfo {
  readonly hasNextPage: boolean;
  maxCursor?: string;
  maxOffset?: number;
}

export class IQueryResult {
  pageInfo!: PageInfo;
  edges?: any[];
}

export type PaymentStatus =
  | 'pending'
  | 'failed'
  | 'confirmed'
  | 'canceled'
  | 'incomplete'
  | 'needsPaymentMethod'
  | 'requiresConfirmation';

export type RolesEnum = 'editor' | 'contributor' | 'administrator' | 'owner';
export const Roles: RolesEnum[] = [
  'owner',
  'administrator',
  'editor',
  'contributor'
];

export class ITrackingParams {
  // tslint:disable-next-line: variable-name
  utm_source?: string;
  // tslint:disable-next-line: variable-name
  utm_medium?: string;
  // tslint:disable-next-line: variable-name
  utm_campaign?: string;
  // tslint:disable-next-line: variable-name
  utm_term?: string;
  // tslint:disable-next-line: variable-name
  utm_content?: string;
}

export type IntegrationType =
  | 'stripe'
  | 'zapier'
  | 'zoom'
  | 'facebook'
  | 'instagram'
  | 'shopify'
  | 'mailchimp'
  | 'eventbrite'
  | 'convertkit'
  | 'flodesk'
  | 'hubspot'
  | 'klaviyo'
  | 'google'
  | 'meta'
  | 'beehiiv'
  | 'googleAds'
  | 'norbyChatSlackBot'
  | 'tiktok'
  | 'tikTokAds'
  | 'postscript'
  | 'attentive'
  | 'pinterest';

export const INTEGRATION_NAMES: { [K in IntegrationType]: string } = {
  google: 'Google Analytics',
  googleAds: 'Google Ads',
  meta: 'Meta Ads',
  shopify: 'Shopify',
  klaviyo: 'Klaviyo',
  beehiiv: 'Beehiiv',
  mailchimp: 'Mailchimp',
  facebook: 'Facebook',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  tikTokAds: 'TikTok Ads',
  attentive: 'Attentive',
  pinterest: 'Pinterest',
  postscript: 'Postscript',
  hubspot: 'HubSpot',
  eventbrite: 'Eventbrite',
  stripe: 'Stripe',
  convertkit: 'ConvertKit',
  zapier: 'Zapier',
  zoom: 'Zoom',
  flodesk: 'Flodesk',
  norbyChatSlackBot: 'Slack'
};

export type ChargeType = 'sms' | 'email' | 'mms';

type CommunicationInteractionType =
  | 'userEnabledSmsForEvent'
  | 'userDisabledSmsForEvent'
  | 'userEnabledEmailForEvent'
  | 'userDisabledEmailForEvent';

export type InteractionType =
  | 'contentReferral'
  | 'singleSendOpen'
  | 'click'
  | 'eventReferral'
  | 'rsvp'
  | 'rsvpRemoved'
  | 'spotifyPlay'
  | 'youtubePlay'
  | 'userClickedPostEventCta'
  | 'userClickedPreEventCta'
  | 'userTappedShare'
  | 'userSavedToGcal'
  | 'userSavedToIcal'
  | 'userTappedDonate'
  | 'shopifyOrderCreated'
  | 'view'
  | 'userConverted'
  | 'userJoined'
  | 'userClickedPageShortLink'
  | 'userClickedClickThroughShortLink'
  | 'userClickedReferralPageShortLink'
  | 'userClickedTrackingShortLink'
  | 'singleSendReceived'
  | 'subscriptionCreated'
  | 'subscriptionPaid'
  | 'productPurchased'
  | CommunicationInteractionType
  | ShortLinkType;

export type AnalyticsEvent =
  | InteractionType
  | 'eventCreated'
  | 'eventUpdated'
  | 'contentCanceled'
  | 'contentDeleted'
  | 'linkCreated'
  | 'linkUpdated'
  | 'linkDeleted'
  | 'userReorderedLinks'
  | 'singleSendCreated'
  | 'singleSendUpdated'
  | 'singleSendDeleted'
  | 'userRequestedRefund'
  | 'slugIssuedRefund'
  | 'publishedToxicNotification'
  | 'publishedToxicEvent'
  | 'publishedToxicSingleSend'
  | 'publishedToxicLink'
  | 'userLoggedIn'
  | 'userLoggedOut'
  | 'teamInviteSent'
  | 'teamInviteAccepted'
  | 'emailVerified'
  | 'stripeAccountLinked'
  | 'zoomAccountLinked'
  | 'userUpdatedProfile';

export type MmsAttachmentSourceType = 'url' | 'firebaseStorage';
export const MmsAttachmentSourceTypes: MmsAttachmentSourceType[] = [
  'url',
  'firebaseStorage'
];

export class MessageAttachment {
  filename?: string;
}

export class MmsAttachment extends MessageAttachment {
  @Expose()
  sourceType!: MmsAttachmentSourceType;

  @Expose()
  source!: string;

  @Expose()
  contentType!: string;

  @Expose()
  contentSize!: number;

  @Expose()
  filename?: string;
}

export type DeliveryType = 'sms' | 'email' | 'mms';

export type NotificationMoments =
  | 'rsvpConfirmation'
  | 'newsletterSend'
  | 'eventReminder'
  | 'eventStart';
export type NotificationMediums = 'email' | 'sms';
export type NotificationDefaults = {
  [key in NotificationMoments]: {
    [key in NotificationMediums]: {
      body?: string;
      subject?: string;
      offset?: number;
      attachCalendarInvite?: boolean;
    };
  };
};

export interface IDropShadowValues {
  h?: number;
  v?: number;
  blur?: number;
  color?: string;
}

export class ISlugGeneralSettings {
  disableNorbyBranding?: boolean;
  termsUrl?: string;
  privacyUrl?: string;
}

export type PromoCodeControlType = {
  id: string;
  controlInstance: string;
  isNew?: boolean;
  delete?: boolean;
};
